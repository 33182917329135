<template lang="pug">
  div#box
    div#header
      comp-header
    div#content
      div#left.column
        comp-screen-share
        comp-live-setting
      div#center
        comp-live-stream
      div#right.column
        comp-participants
        comp-chat
    room-device-dialog(ref="roomDeviceDialog" @nextStep="showBeautyPresettingDialog")
    room-beauty-dialog(ref="roomBeautyDialog")
</template>

<script>
// import LibGenerateTestUserSig from '@/utils/pusher/lib-generate-test-usersig.min.js';
// eslint-disable-next-line no-unused-vars
import {SET_APP_INFO, SET_ROOM_ID, UPDATE_ROOM_NAME, UPDATE_USER_INFO,} from '@/constants/pusher/mutation-types';
import {sdkAppId,} from '@/config/pusher/basic-info-config';
import compHeader from '@/components/pusher/comp-header';
import compScreenShare from '@/components/pusher/comp-screen-share';
import compLiveSetting from '@/components/pusher/comp-live-setting/index.vue';
import compLiveStream from '@/components/pusher/comp-live-stream';
import compParticipants from '@/components/pusher/comp-participants';
import compChat from '@/components/pusher/comp-chat';
import roomDeviceDialog from '@/components/pusher/comp-pre-setting/room-device-dialog.vue';
import roomBeautyDialog from '@/components/pusher/comp-pre-setting/room-beauty-dialog.vue';
import {getUserSig,liveEnd,saveTelecastTeacherHour_api} from '@/api/live'
// import {mapState} from "vuex";
// eslint-disable-next-line no-unused-vars
import {sessionGet, sessionSet,sessionClear} from "@/utils/local";
import {
  dateFormat
} from "utils/dateFormat";
export default {
  name: 'Pusher',
  components: {
    compHeader,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compParticipants,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
  },
  data() {
    return {
      timerId: '', //计时器id
      timer: 0, //计时器
    };
    },
  computed: {
    userInfo() {
      return sessionGet('userInfo')
    }
  },

  methods: {
    /* 视频计时*/
    async saveTelecastTeacherHour() {
      var that=this;
      saveTelecastTeacherHour_api({
        resourcesId: sessionGet('liveCourseRoom').telecastId,
        length: that.timer,
        date: dateFormat('yyyy-MM-dd hh:mm:ss'),
        userId: sessionGet('userInfo').user_id
      });
    },
    //清空定时器
    funClearTimer(){
      clearInterval(this.timerId);
    },
    fun1(){
      console.log('取消刷新')
    },
    //开始计时
    startTime(){
      this.handleAddConfirmBack();
      /*开始直播计时*/
      this.timer = 0;
      this.timerId = setInterval(() => {
        this.timer++
      }, 1000)
    },
    handleAddConfirmBack () {
      let that=this;
        const STATE = {
            TITLE: document.title,
            URL: window.location.pathname + window.location.search
        };
        window.history.pushState(STATE, STATE.TITLE, STATE.URL);
        function confirm (e) {
            e.stopPropagation();// 阻止事件传播
            setTimeout(()=>{
              that.$confirm('返回页面将结束直播，是否返回?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(async () => {
                window.history.go(-1);
              }).catch(()=>{
                window.history.pushState(STATE, STATE.TITLE, STATE.URL);
              })
            },200)
        }
        window.addEventListener('popstate', confirm, false);
        this.$once('hook:beforeDestroy', function () {
            window.removeEventListener('popstate', confirm);
        });
    },
    //页面异常退出事件绑定
    async watchTabUnload() {
       console.log(2)
       var that=this;
       window.onbeforeunload = function (e) {
         console.log(3)
           e = e || window.event;  
               that.saveTelecastTeacherHour();
               //liveEnd({"telecastStartId": that.telecastId});
           var timer2 = setInterval(function(){ 
               that.fun1();
               clearInterval(timer2);
           }, 1000);     //在点击取消关闭页面按钮时启动计时器，执行一次就暂停
           if (e) {
               e.returnValue = '关闭提示';
           }
           return '关闭提示';
       };
       window.addEventListener("beforeunload", function (event) {
           event.returnValue = "关闭提示";
       });
     },
    // 显示设置预设置弹窗
    showDevicePresettingDialog() {
      this.$refs.roomDeviceDialog.handleShowDeviceDialog();
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog() {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog();
    },
    // 设置TUIPusher配置信息
    handlePusherInfo: async function () {
      // let appInfo = sessionGet('appInfo')
      let roomInfo = sessionGet('roomInfo')
      let userInfo = sessionGet('userInfo')
      this.$nextTick()
      if (userInfo) {
        this.$store.commit(UPDATE_USER_INFO, {
          userId: userInfo.user_id,
          userName: userInfo.nick_name,
          userAvatar: userInfo.avatar
        })
      } else {
        this.$message.error('请登录')
        this.$router.push({path: '/login'})
      }
      this.$store.commit(SET_ROOM_ID, roomInfo.roomId);
      this.$store.commit(UPDATE_ROOM_NAME, roomInfo.roomName);

      // if (appInfo) {
      //   this.$store.commit(SET_APP_INFO, {
      //     sdkAppId: appInfo.sdkAppId,
      //     userSig: appInfo.userSig,
      //     shareUserSig: appInfo.shareUserSig,
      //   });
      //   return
      // }
      if (sdkAppId === '') {
        alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/basic-info-config.js`);
      }
      // 获取签名
      let userSig = await this.getUserSig().then(res => {
        return res
      })
      let shareUserSig = await this.getUserSig('share').then(res => {
        return res
      })
      this.$store.commit(SET_APP_INFO, {
        sdkAppId,
        userSig,
        shareUserSig,
      });
      // sessionSet('appInfo', {sdkAppId, userSig, shareUserSig})
    },
    // 退出直播间
    async handleExit() {
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout() {
      // 处理退出登录
    },
    async getUserSig(type) {
      let userId = this.userInfo.user_id
      if (type === 'share') {
        userId = `share_${userId}`
      }
      let res = await getUserSig({userId})
      return res.data
    },

  },
  async created() {
    await this.handlePusherInfo();

    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
  },
  mounted() {
    this.showDevicePresettingDialog();
    this.watchTabUnload();
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
    clearInterval(this.timerId);
       this.saveTelecastTeacherHour();
       console.log(sessionGet('liveCourseInfo'));
       if(sessionGet('liveCourseInfo')){
         liveEnd({"telecastStartId": sessionGet('liveCourseInfo').id});
         sessionClear('liveCourseInfo');
       }
    window.onbeforeunload=undefined;
  },
};
</script>

<style lang="stylus">
//@import '~assets/style/black-element-ui.styl';
#box
  font-family Avenir, Helvetica, Arial, sans-serif
  --webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  width 100%
  height 100vh
  position relative
  color #FFFFFF

  div#header
    width 100%
    height 60px
    background-color #1C2131
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
    font-size 1.6rem

  div#content
    position absolute
    top 60px
    width 100%
    left 0
    bottom 0
    display flex
    background-color #1C2131
    font-size 1.6rem

    div#left
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color #383F54

    div#center
      height 100%
      flex-grow 1

    div#right
      width 20%
      min-width 300px
      max-width 406px
      height 100%
      background-color #383F54

    .column
      padding 8px
      display flex
      flex-direction column

      > div:not(:first-child)
        margin-top 8px

      > div:last-child
        flex-grow 1
</style>
